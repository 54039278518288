import request from '@/utils/request'

// api地址
const api = {
  all: '/frm/activityFormField',
  info: '/frm/activityFormField/',
  save: '/frm/activityFormField',
  update: '/frm/activityFormField/update',
  delete: '/frm/activityFormField/',
  formData: '/frm/activityFormField/formData/',
  deleteBatch: '/frm/activityFormField/batch',
  page: '/frm/activityFormField/page',
  enable: '/frm/activityFormField/enable/',
  verify: '/frm/activityFormField/verify/'
}

/**
 * 查询所有
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function all(params) {
  return new Promise((resolve, reject) => {
    request.get(api.all, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 查询所有
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function info(id) {
  return new Promise((resolve, reject) => {
    request.get(api.info + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 查询所有
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function formData(id) {
  return new Promise((resolve, reject) => {
    request.get(api.formData + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function save(data, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.post(api.update, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.save, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}
/**
 * 根据id删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteById(id) {
  return new Promise((resolve, reject) => {
    request.delete(api.delete + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 批量删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteBatch(data) {
  return new Promise((resolve, reject) => {
    request.delete(api.deleteBatch, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

export function enable(id, data) {
  return new Promise((resolve, reject) => {
    request.post(api.enable + id, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

export function verify(id, data) {
  return new Promise((resolve, reject) => {
    request.post(api.verify + id, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
